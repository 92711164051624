import React from 'react'
import {
    motion
} from 'framer-motion';
import { auth } from '../../../firebase';

const NextStep = () => {
  return (
   <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
   className='Container'
   style={{
    height: '100vh',
    width: '100%',
   }}
   >
    <h1
    style={{textAlign: 'center', 
    color: 'white', 
    fontSize: '2rem',
    marginTop: '2rem',
}}
    >
        Identity and Transport Verification (Please Read Through)
    </h1>
    <p>
        The next step is to verify your identity and car information. Please ensure that you have the following before continuing on to the next page: 
    </p>
        <ol 
        style={{
            color: 'white',
            fontSize: '1rem',
        }}
        >
            <li
            style={{
                color: 'white',
                fontSize: '1rem',
                marginTop: '1.5rem',
            }}
            > Photos of your taxi license (front and back):</li>
            <ul>
                <li>These photos must be clear and all 4 corners of the license must be visible. </li>
                <li>Your licensse must also be up to date. </li>
            </ul>
            <li
            style={{
                color: 'white',
                fontSize: '1rem',
                marginTop: '1.5rem',
            }}
            >
                Photo of your taxi license plate: 
                <ul>
                    <li>This photo must also be clear and all 4 corners of the license plate must be visible</li>
                </ul>
            </li>
            <li
            style={{
                color: 'white',
                fontSize: '1rem',
                marginTop: '1.5rem',
            }}
            >
                Make, Model, Year and Color of your vehicle.
            </li>
        </ol>
        <p> Note: Once the listed information has been submitted, the verification process will take some time (about 2 - 4 days). Once your account has been reviewed,
            you will receive either an email or a phone call. Please look out for both. 
        </p>
        <a href={`/verification-info/${auth.currentUser?.uid}`}
        >
            <button>
                Continue
            </button>
        </a>
   </motion.div>
  )
}

export default NextStep;