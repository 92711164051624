import React, {
    useState, 
    useEffect
} from 'react';
import './styles.css'; 
import {
    db,
    auth
} from '../../../firebase';
import  Logo  from '../../images/Logo.svg';

const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [driver, setDriver] = useState(null)
    const [error, setError] = useState('')

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                if (user.emailVerified) {
                    db.collection('drivers').doc(user.uid).get().then((doc) => {
                        setDriver(doc.data())
                    })
                    if (driver?.isVerified === 'true') {
                        alert(`Welcome ${driver?.name}`)
                        window.location.href = '/verified/' + user.uid
                    } else if (driver?.isVerified === 'false' && driver?.infoSubmitted === 'true') {
                        alert(`Welcome ${driver?.name}`)
                        window.location.href = '/awaiting-verification/' + user.uid
                    } else if (driver?.isVerified === 'false' && driver?.infoSubmitted === 'false') {
                        alert(`Welcome ${driver?.name}`)
                        window.location.href = '/next-step/' + user.uid
                    }
                } else {
                    setError('Please verify your email address')
                }
            } else {
                setDriver(null)
            }
        })
    }, [driver])


        
                
            

    const handleLogin = (e) => {
        e.preventDefault()
   if (email === '' || password === '') {
            setError('Please fill in all fields')
        } else {

            auth
            .signInWithEmailAndPassword(email, password)
            .then(userCredentials => {
                const user = userCredentials.user;
                console.log('Logged in with:', user.email);
            }).then (() => {
                setEmail('')
                setPassword('')
            }).catch((error) => {
                setError('Error', error.message)
            })

            auth.onAuthStateChanged((user) => {
                if (user) {
                    if(user.emailVerified){
                        setDriver(user)
                        db.collection('drivers').doc(user.uid).get().then((doc) => {
                            setDriver(doc.data())
                        })
                        if (driver?.isVerified === 'true') {
                            window.location.href = `/verified/${auth.currentUser.uid}`
                        } else if (driver?.infoSubmitted === 'true' && driver?.isVerified === 'false') {
                           window.location.href = '/awaiting-verification/' + user.uid
                        } else {
                            window.location.href = `/next-step/${auth.currentUser.uid}`
                        }
                    } else {
                        setError('Please verify your email.')
                        auth.signOut();
                        window.location.href = '/login'
                    }
                } else {
                    setDriver(null)
                }
            })

    }
}
        
    
  return (
    <div className="container">
    <img 
        src={Logo}
        alt="OT268"
        style={{width: '100px', height: '100px', marginBottom: '50px'}}
    />

    <form onSubmit={handleLogin}>
        <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
        />
        <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
        />
        <button
        className="button"
        style={{
            margin: '0 auto',
            alignSelf: 'center', 
            display: 'block',
            marginLeft: 'auto',
        }}
        type="submit">Login</button>
        <p
        style={{
            marginTop: '20px',
            color: 'grey'
        }}
        >
            Don't have an account? <a href="/SignUp"
            style={{
                color: '#0d6efd', 
                textDecoration: 'none'
            }}
            >Sign Up</a>
        </p>
    </form>
    {error && <p style={{color: 'red'}}>{error}</p>}
</div>
    );
}

export default Login;