import React from "react";
import './policies.css';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Navbar from '../../ui/navbar';
import Footer from '../../ui/footer';

const PP = () => {
    return(
    <>
    <Navbar />
    <motion.div 
          className="body" 
          initial= {{opacity: 0}}
          animate= {{opacity: 1,
          transition: {
          delay: 0.5,
          staggerChildren: 0.5
         }}}>
        <h1>Privacy Policy for Operation Traddingz 268</h1>

<p> 
    Operation Traddingz 268 is committed to protecting thed privacy and personal information of its users in accordance
    with the Data Protection Act of Antigua and Barbuda. This Privacy Policy sets out how Operation Traddingz 268 collects, uses, and protects
    personal information.
</p>

<p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to 
    <Link to="contact-us" 
    style={{color: 'white', 
    textDecoration: 'none'}} >
    contact us</Link>.</p>

<p>
    This Privacy Policy applies only to our online activities and is valid for visitors to our website and/or application 
    with regards to the information that they shared and/or collect in Operation Traddingz 268. This policy is not applicable
    to any information collected offline or via channels other than this website.
</p>

<br></br>
<h2>Consent</h2>
<p>
    By using our website and/or application, you hereby consent to our Privacy Policy and agree to its terms.
</p>

<h2>Information we collect</h2>

<p>
Operation Traddingz 268 collects personal information such as name, email, phone number, payment and location data, 
for the purpose of providing and improving its services. The company may also collect information from third-party 
service providers who access this information for payment processing, customer support, and fraud prevention. 
Any personal information collected by Operation Traddingz 268 will be processed lawfully, fairly, and transparently.
</p>

<br></br>

<h2>How we use your information</h2>

<p>
Operation Traddingz 268 uses personal information to provide and improve its services,
communicate with users, and comply with legal obligations. 
The company may also use aggregated or anonymized data for 
research and analytics purposes, 
but this information will not be personally identifiable.
</p>

<br></br>

<h2> Protection of Information</h2> 
<p>
Operation Traddingz 268 takes reasonable measures 
to protect user information from unauthorized access, 
use, or disclosure. The company maintains appropriate 
technical and organizational measures to safeguard personal 
information against accidental or unlawful destruction or 
accidental loss, alteration, unauthorized disclosure, or access.
</p>

<br></br>

<h2>User Rights</h2>
<p>
    Users have the right to access, correct, or delete their personal information 
    through the Operation Traddingz 268 app or website, in accordance with the Data Protection
    Act. Users may also have the right to restrict or object to the processing of their personal information, 
    and to receive a copy of their personal information in a structured, commonly used, 
    and machine-readable format. 
</p>

<br></br>

<h2>Sharing of Information</h2>
<p>
    Operation Traddingz 268 may share personal information with third-party service
    providers who access this information for payment processing, customer support, and fraud prevention purposes, provided they also comply with the Data Protection Act. 
    The company maybalso share aggregated or anonymized data for research and analytics purposes. 
</p>

<br></br>

<h2>Data Retention</h2>
<p>
    Operation Traddingz 268 will retain personal information for as long as necessary 
    to fulfill the purposes outlined in this Privacy Policy or as required by law. The length
    of time that personal information will be retained depends on the type of information and 
    the purposes of which it is collected. 
</p>

<p>
    Personal  information used for payment processing will be retained for the duration
    of the transaction and any applicable dispute resolution period. Location data may be retained for 
    up to 30 days to improve the accuracy of Operation Traddingz 268's services. User account information, including name, phone-number and 
    email address, will be retained for as long as the user has an active account with Operation Traddingz 268. 
</p>

<p>
    Operation Traddingz 268 may also retain information for a longer period of time if required to comply with
    a legal obligation, resolve disputes, or enforce its agreements.
    Once the personal information is no longer necessary for the purposes for which it was collected, 
    it will be securely deleted or anonymized. 
</p>

<p>
    Users may request the deletion of their personal information at any time by contacting 
    Operation Traddingz 268 customer support. Operation Traddingz 268 will promptly delete any 
    personal information that is no longer necessary for the purposes for which it was collected. 
    However, certain personal information may be retained fo ra longer period of time if necessary 
    to comply with legal obligations, resolve disputes, or resolve disputes.
</p>

<br></br>

<h2>Changes to this Privacy Policy</h2>

<p>Operation Traddingz 268 regularly updates 
   its privacy policy to reflect any changes in its 
   practices or legal requirements under the 
   Data Protection Act. We will not notify users 
    of changes to this policy, but we will post
    the most up-to-date version on our website.
</p>

<br></br>
<p>
   By using our website and/or application,
   you hereby consent to our Privacy Policy
   and agree to its terms.
</p>

        </motion.div>
    <Footer />
    </>
    )
}

export default PP;