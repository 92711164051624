import styled from 'styled-components'
import { Link } from 'react-router-dom'


export const Button = styled(Link)`border-radius: 50px; 
background: ${({primary}) => (primary ? '#0d6efd' : '#010606')};
white-space: nowrap;
padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
color: ${({dark}) => (dark ? '#010606' : '#fff')};
font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')}; 
outline: none;
text-decoration: none;
border: none; 
cursor: pointer;
display; flex;
justify-content: center;
align-items: center;


&:hover {
    transition: 200ms ease-in;
    background: ${({primary}) => (primary ? '#0d6edf' : '#0d6efd')};
}
`

