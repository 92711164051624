import React from 'react'; 
import './policies.css';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';

const Refund = () => {


    return (
        <motion.div className="refund"
        initial={{opacity:0}}
        animate={{opacity:1}}
        exit={{opacity:0}}
        transition={{duration:0.5}}
        >
                <h3><strong>Refund Policy for OT268</strong></h3>
                
                <br></br>

                <p>
                    At Operation Traddingz 268, we strive to provide our drivers 
                    with the best possible service We understand that issues may arise, and we offer refunds only in certain situations. 
                </p>

                <br></br>

                <h3><strong>Refund Eligibility</strong></h3>

                <p>
                    Refunds are only available in specific situations, such as if a
                     member reports an unauthorized charge or a technical
                     issue that can't be resolved within a reasonable time period.
                     Refunds are not typically offered for issues related to content
                     or personal preferences.  
                </p>

                <br></br>

                <h3><strong>Requesting a Refund</strong></h3>
                <p>
                    If a member believes they are eligible for a refund, 
                    they should contact Operation Traddingz 268 customer 
                    service for assistance. Operation Traddingz 268 reserves 
                    the right to grant or deny any refund requests, and may require 
                    additional information or documentation to process a refund.
                </p>
                
                <br></br>

                <h3><strong>Refund Processing</strong></h3>
                <p>
                    Refunds will generally be issued in the form of a credit
                    to the member's account, which can be used to offset future 
                    subscription charges. If a member cancels their subscription 
                    mid-billing cycle, they will not receive a refund for the unused 
                    portion of that billing cycle, but they will retain access to the 
                    service until the end of the current billing period.
                </p>

                <br></br>

                <p> 
                    Please note that Operation Traddingz 268 reserves the right to modify 
                    or update this refund policy at any time, 
                    and it is the member's responsibility to review 
                    this policy periodically for any changes. 
                </p>

        </motion.div>

        
    )
}

export default Refund