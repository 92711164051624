import { MotionConfig } from 'framer-motion';
import React, {
    useState, 
    useEffect
} from 'react';
import {auth, db, storage} from '../../../firebase';
import {motion} from 'framer-motion';

const VeriffInfo = () => {

    const [loading, setLoading] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [year, setYear] = useState('');
    const [licenseFront, setLicenseFront] = useState('')
    const [licenseBack, setLicenseBack] = useState('')
    const [licensePlate, setLicensePlate] = useState('')
    const [color, setColor] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (make === '' || model === '' || year === '' || color === '' || licenseFront === '' || licenseBack === '' || licensePlate === '') {
            setError('Please fill all the fields');
        } else {
        e.preventDefault();
        setLoading(true);
        const user = auth.currentUser;
        const uid = user.uid;
        const ref = storage.ref();
        const licenseFrontRef = ref.child(`licenseFront/${uid}`);
        const licenseBackRef = ref.child(`licenseBack/${uid}`);
        const licensePlateRef = ref.child(`licensePlate/${uid}`);
        const licenseFrontPromise = licenseFrontRef.put(licenseFront);
        const licenseBackPromise = licenseBackRef.put(licenseBack);
        const licensePlatePromise = licensePlateRef.put(licensePlate);
        Promise.all([licenseFrontPromise, licenseBackPromise, licensePlatePromise])
        .then(() => {
            return Promise.all([licenseFrontRef.getDownloadURL(), licenseBackRef.getDownloadURL(), licensePlateRef.getDownloadURL()])
        })
        .then((urls) => {
            const licenseFrontUrl = urls[0];
            const licenseBackUrl = urls[1];
            const licensePlateUrl = urls[2];
            return db.collection('drivers').doc(uid).update({
            status: 'inactive',
            isVerified: 'false',
            infoSubmitted: 'true',
            rating: 5.0,
            trips: 0,
            earnings: 0,
            car :{
                make: make,
                model: model,
                year: year,
                color: color
            }, 
            license: {
                front: licenseFrontUrl,
                back: licenseBackUrl,
            },
            licensePlate: licensePlateUrl
            })
        })
        .then(() => {
            setColor('');
            setMake('');
            setModel('');
            setYear('');
            setLicenseFront('');
            setLicenseBack('');
            setLicensePlate('');
            setLoading(false);
            alert('Your information has been submitted. Please wait for a call or email from our team. Thank you!')
            window.location.href = '/login'
        })
        .catch((error) => {
            setLoading(false);
            alert(error.message);
        })
    }
    }



    const handleLicenseFront = (e) => {
        if(e.target.files[0]){
            setLicenseFront(e.target.files[0]);
        }
    }


    
    const handleLicenseBack = (e) => {
        if(e.target.files[0]){
            setLicenseBack(e.target.files[0]);
        }
    }

    const handleLicensePlate = (e) => {
        if(e.target.files[0]){
            setLicensePlate(e.target.files[0]);
        }
    }



  return (
  <motion.div
    initial={{ opacity: 0 }}    
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
    style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#f5f5f5'

    }}
    className='Container'>
    <h1 style={{
        color: 'black',
        textAlign: 'center',
        marginTop: '20px',
    }}>
        Identity and Transport Verification
    </h1>

    <form onSubmit={handleSubmit}>
    <label 
    style={{color: 'grey', marginTop: '20px', display: 'block'}}
    >
    Upload the front of your license
    <input
        type='file'
        onChange={handleLicenseFront}
    />
    </label>
   <label 
    style={{color: 'grey', marginTop: '20px', display: 'block'}}
   >
    Upload the back of your license
    <input
        type='file'
        onChange={handleLicenseBack}
    />
    </label>
    <input 
        type='text'
        placeholder='Car Make'
        value={make}
        onChange={(e) => setMake(e.target.value)}
    />
    <input
        type='text'
        placeholder='Car Model'
        value={model}
        onChange={(e) => setModel(e.target.value)}
    />
    <input
        type='text'
        placeholder='Car Year'
        value={year}
        onChange={(e) => setYear(e.target.value)}
    />
    <input  
        type='text'
        placeholder='Car Color'
        value={color}
        onChange={(e) => setColor(e.target.value)}
    />
    <label 
    style={{color: 'grey', marginTop: '20px', display: 'block'}}
    >
    Upload a picture of your license plate
    <input
        type='file'
        onChange={handleLicensePlate}
    />
    </label>

    
        {error && <p style={{color: 'red'}}>{error}</p>}
    
    {
        loading ? (
            <button disabled>
                Loading...
            </button>
        ) : (
            <button type='submit'>
            Submit
            </button>
        )
    }

    
    </form>
  </motion.div>
  )
}

export default VeriffInfo;