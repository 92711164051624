import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import { motion } from 'framer-motion'
import { Button } from './ButtonElement'
import './navbar.css'
import logo from '../images/Logo2.svg'

export const pathVariants = {
    hidden: {
        opacity: 0,
        pathLength: 0 

    }, 
    visible: {
        opacity: 1, 
        pathLength: 1, 
        transition: {
            duration: 2, 
            ease: "easeInOut"
        }
    }
    
}

const Navbar = () => {
    const [click, setClick] = useState
    (false);
    // const [dropdown, setDropdown] = useState
    // (false);

   

    const handleClick = () => setClick
    (!click);
    const closeMobileMenu = () => setClick
    (false);

    const LogoSrc = () => { 
        return <img src={logo} alt="download nowww"/>
    };
    
  
    return (
    <>
        <nav className="navbar">
        
           <Link to='/' className='navbar-logo' >
               <motion.div
                 initial={{ x: -500 }}
                 animate={{ x: -3 }}
                 transition={{type:'spring', stiffness: 120, delay: 2.5 }}
               >
            
            <motion.i className='navbar-logo' >
                <LogoSrc/>
            </motion.i>  
            </motion.div> 
           </Link>
        <div className='menu-icon' onClick={handleClick}>
            <Hamburger color="grey" toggled={click} toggle={setClick} size={20} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'} >
            <li className='nav-item'>
                <Button to='/' className="nav-links" onClick={closeMobileMenu}>
                    Home
                </Button>
            </li>
            <li className='nav-item' >
                <Button to='/about' className="nav-links"  > 
                   About
                </Button>   
            </li>
            <li className='nav-item'>
                <Button to='/contact-us' className="nav-links" onClick={closeMobileMenu}>
                    Contact-Us
                </Button>
            </li>
            <li className='nav-item'>
                <Button to='/about' className="nav-links" onClick={closeMobileMenu}>
                    Becoming a Driver
                </Button>
            </li>
           

           
        </ul>
       
        </nav>
    </>    
    )
 }



export default Navbar