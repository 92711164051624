import React from 'react';
import InfoSection from './components/ui/InfoSection';
import {motion}  from 'framer-motion';
import { homeObjOne, homeObjTwo} from './components/ui/InfoSection/Data'
import Footer from './components/ui/footer';
import Navbar from './components/ui/navbar';

export default function Homepage() {
    return(
    <>
    <Navbar />
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5, staggerChildren: 0.2 }} >
    <InfoSection
    {...homeObjOne} />
    <InfoSection
    {...homeObjTwo} />
    </motion.div>
    <Footer />
    </>
)
}