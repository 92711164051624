import React, { useState, useRef }from "react";
import { motion } from "framer-motion";
import { db } from "../../../firebase";
import './cmngsn.css';
import ReCaptcha from 'react-google-recaptcha';
import Axios from 'axios';

const Cmngsn = () => {

    const recaptcha = useRef();
    const [email, setEmail] = useState("");
    
    const [loader, setLoader] = useState(false);
    
    const [error, setError] = useState({});

    const [token, setToken] = useState("");
    
    
  
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setLoader(true);
      setError({});
      if(!email) {
        setError({
          email: "Email is required"
        });
        setLoader(false);
      }
      else if(!token) {
        setError({
          token: "Please verify that you are not a robot"
        });
        setLoader(false);
      }
      else {
        Axios.post('https://us-central1-ot268-bdef4.cloudfunctions.net/checkRecaptcha', {
          email,
          token
        })
        .then(() => { 
          db.collection("mailingList")
      .add({
        email: email,
        date: new Date()
      })
      .then(() => {
        setLoader(false);
        alert("Your message has been sent! We will get back to you soon.");
      })
          setLoader(false);
          setEmail("");
          setToken("");
        })
        .catch((error) => {
          setLoader(false);
          setError(error);
          alert(error)
        });
      }
    }

    
  
    return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="Coontainer">
            <h1
            style={{color: 'white', textAlign: 'center', marginTop: '50px', marginBottom: '50px'}}
            >Coming Soon</h1>
            <h2
            style={{color: 'white', textAlign: 'center', marginTop: '50px', marginBottom: '50px'}}
            >Get notified when we launch!</h2>
            <form onSubmit={handleSubmit}>
              <input 
                value={email}
                name="email" 
                type="email"
                placeholder="Enter your email"
                onChange={(e)=>setEmail(e.target.value)} 
                required/>

                <ReCaptcha
                style={
                  {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px 0"

                }
                }
                ref={recaptcha}
                sitekey="6LcJpaQfAAAAAF7HjcT6ezqwY5tRbhEiusBD_t9y"
                onChange={token => setToken(token)}
                onExpired={e => setToken("")}
                />

                <button
                  type="submit" 
                  style={{ background: loader ? "#ccc" : "black" }}>Subscribe</button>
            </form>
          </motion.div>
    )
}


export default Cmngsn;



