import React from 'react'
import {motion} from 'framer-motion'
import Navbar from '../../ui/navbar'
import '../about/about.css'

const Guidelines = () => {

  return (
    <>
        <Navbar />
        <motion.div
        className="about"
        initial= {{opacity: 0}}
        animate= {{opacity: 1,
        transition: {
        delay: 0.5,
        staggerChildren: 0.5
       }}}
       >
        <h2>
            Driver Guidelines
        </h2>
        <h3>
            
        </h3>
       </motion.div>
    </>
  )
}

export default Guidelines