
import React, {
  useState
} from 'react';
import './styles.css'; 
import {
  db,
  auth
} from '../../../firebase';
import  Logo  from '../../images/Logo.svg';
 
const SignUp = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false)


  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (name === '' || email === '' || password === '' || password1 === '') {
        throw new Error('Please fill in all fields');
      }
      if (password !== password1) {
        throw new Error('Passwords do not match');
      }
      if (password.length < 6) {
        throw new Error('Password must be at least 6 characters');
      }
  
      await auth.createUserWithEmailAndPassword(email, password);
      await auth.currentUser.sendEmailVerification({
        url: 'https://ot268-bdef4.firebaseapp.com',
        handleCodeInApp: true,
      });
  
      const driverRef = db.collection('drivers').doc(auth.currentUser.uid);
      await driverRef.set({
        name,
        email,
        phone,
        status: 'inactive',
        profilePhoto: null,
        isVerified: false,
        infoSubmitted: false,
        createdAt: new Date(),
      });
  
      alert('Please check your email for verification (If you do not see an email, check your spam folder).');
      setLoading(false);
      setName('');
      setEmail('');
      setPhone('');
      setPassword('');
      setPassword1('');
      auth.signOut();
      window.location.href='/login';
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };


function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}



      
  
return (
  <div className="container">
  <img 
      src={Logo}
      alt="OT268"
      style={{width: '100px', height: '100px', marginBottom: '50px'}}
  />
  
  
  <form onSubmit={handleSignUp}>


      <input 
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
      />
      <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
      />
      <input
          type="phone"
          placeholder= '(123) 456-7890'
          value={phone}
          onChange={(e) => setPhone(formatPhoneNumber(e.target.value))}
      />
      <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
      />
      <input
          type="password"
          value={password1}
          onChange={(e) => setPassword1(e.target.value)}
          placeholder="Confirm Password"
      />
      
   
  {
    loading ? (
      <button disabled
      className="button"
      style={{
          margin: '0 auto',
          alignSelf: 'center', 
          display: 'block',
          marginLeft: 'auto',
      }}
      type="submit">Sign-Up</button>
    ) : (
      <button
      className="button"
      style={{
          margin: '0 auto',
          alignSelf: 'center', 
          display: 'block',
          marginLeft: 'auto',
      }}
      type="submit">Sign-Up</button>
    )
  }
     
      <p
      style={{
          marginTop: '20px',
          color: 'grey'
      }}
      >
          Already have an account? <a href="/login"
          style={{ 
            color: "#0d6efd", 
            textDecoration: "none"
           }}
          >Login</a>
      </p>
  </form>
  {error && <p style={{color: 'red'}}>{error}</p>}
  {/* {success && <p style={{color: 'green'}}>{success}</p>} */}
</div>
  );
}

export default SignUp;