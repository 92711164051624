import React from 'react';
import Navbar from '../../ui/navbar';
import Footer from '../../ui/footer';
import { motion } from 'framer-motion'; 
import './about.css';

export default function About() {
    return (
        <>
        <Navbar />
        <motion.div className="about"
         initial= {{opacity: 0}}
         animate= {{opacity: 1,
         transition: {
         delay: 0.5,
         staggerChildren: 0.5
        }}}
        >
            <h2>Short and Sweet</h2>
            <h3>We understand that nobody really reads these...so we'll get straight to the point: </h3>
            <p> We connect people who need a taxi, to professional taxi drivers who need flexibilty in their schedules and dont want to pay a cut on their trips. 
                If you're looking for a ride we got you covered. 
                If you're a taxi driver who wants to make more money,
                whilst enjoying the freedom of a flexible schedule we got you covered.</p>

             <h2>Our Mission</h2>
            <p>
                We are a team of people who are passionate about optimizing 
                the transportation industry in Antigua and Barbuda. 
                We aspire to be the number one ride-sharing service in the country,
                and we plan on doing this through giving you the best experience possible.           
            </p>

            <h2> For the Users </h2>
            <p>
                The application consists of a simple interface that allows you to request a ride,
                and to track your driver as they make their way to you. You can also track your trip in real time.
                We also provide you with the ability to rate your driver, and to provide feedback on your experience.
                Our service is designed to be as easy as possible for you to use.
                We want you to be able to get a ride as quickly as possible,
                and we want you to be able to do it with as little hassle as possible.
                We aspire to keep steady, across the board pricing, and to provide you with the best drivers in the country. 
                We are currently in the process of development, and we plan on launching on May 25th, 2023.
                <a href="/coming-soon">
                    <button
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginTop: '10px'
                    }}
                    > 
                        Click here to subscribe to our mailing list
                    </button>
                </a>
            </p>


            <h2>For the Drivers (Please Read Through) </h2>
            <p>
                Our service is designed to give you the flexibility you need to make more money.
                We understand that you have a life outside of driving, and we want to give you the freedom to live it. 
                We want to give you the opportunity to make more money, and to do so with less effort.
                The service will be launching on May 1st 2023. Once we launch, you'll be able to start driving! 
                In order to do so you must be a licensed taxi driver, 
                and we have a verification process you must undergo to ensure that you're ready. 
                Don't worry, it's very simple and you'll be on the road with us in no time. 
                As far as pricing goes, we do not take any cuts from your trips. The service will be free for 10 days, followed by a monthly charge of $90ECD. 
                We hope you'll join us on this journey, and we look forward to seeing you on the road! 

                <a style={{color: 'white', textDecoration: 'none'}}
                href="/signup">
                    <button
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginTop: '10px'
                    }}  
                    >Click here to Sign Up</button>
                </a>

            </p>
        </motion.div>
        <Footer />
        </>
    )
}