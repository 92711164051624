// import logo from './logo.svg';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'; 
import './App.css';
// import Team from './components/pages/meet-the-team/team'
import Contact from './components/pages/contact/contact'
import About from './components/pages/about/about'
import Homepage from './homepage'
//import Protocols from './components/pages/covid-protocols'
import Tou from './components/pages/policies/Terms'
import PP from './components/pages/policies/Privacy'
import Refund from './components/pages/policies/Refund'
import Cmngsn from './components/pages/cmngsn/index'
import Login from './components/pages/Auth/Login'
import SignUp from './components/pages/Auth/SignUp'
import NextStep from './components/pages/Identity/NextStep'
import VeriffInfo from './components/pages/Identity/VeriffInfo'
import Guidelines from './components/pages/Guidelines/guidelines'
import {motion} from 'framer-motion'

function App() {

  const AwaitingVeriff = () => {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column'
        }}
      >
      <h1> Awaiting Verification </h1>
      <p
        style={{
          color:'grey'
        }}
      >
        Thank you for submitting your information. Please wait for a call or an email from our team.
      </p>
      <button
        style={{
          backgroundColor: '#0d6efd',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '5px',
          marginTop: '20px'
  
        }}
        >
          <a href="/"
          style={{
            color: 'white',
            textDecoration: 'none', 
          }}
          >Go to Homepage</a>
       </button>

      </motion.div>
    )
  }



  const Verified = () => {
    return (
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}

      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column'
      }}>
        <h1>Verified</h1>
        <p
        style={{
          color: 'grey'
        }}
        >
          You have been verified!
          Launch is in on the 21st of November 2022.
          You can start using the app once it is launched.
        </p>
        <button
        style={{
          backgroundColor: '#0d6efd',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '5px',
          marginTop: '20px'
  
        }}
        >
          <a href="/"
          style={{
            color: 'white',
            textDecoration: 'none', 
          }}
          >Go to Homepage</a>
       </button>
      </motion.div>
    )
  }

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/guidelines" element={<Guidelines />} />
        <Route exact path="/refund-policy" element={<Refund />} />
        <Route exact path="/terms" element={<Tou />} />
        <Route exact path="/privacy-policy" element={<PP />} />
        <Route path="/coming-soon" element={<Cmngsn />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route exact path="/next-step/:id" element={< NextStep/>} />
        <Route exact path="/verification-info/:id" element={<VeriffInfo />} />
        <Route exact path="/verified/:id" element={<Verified />} />
        <Route exact path="/awaiting-verification/:id" element={<AwaitingVeriff />} />
      </Routes>
    </Router>
  );
}

export default App;
