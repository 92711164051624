import React from 'react'
import styled from 'styled-components'

const Icon = styled.i`
font-size: 18px;
margin-right: 16px;
color: white;

&: hover {
	color: #007bff;
	transition: 200ms ease-in;
}
`

const Container = styled.div`
    padding: 60px 80px;
    background: black;
	left: 0;
	right: 0;
	bottom: 0;
`
const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
max-width: 1000px;
margin: 0 auto;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 60px;
`
const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: 20px;
    @media (max-width: 1000px){
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
    }
`

const Link = styled.a`
    color: white;
    margin-bottom: 20px;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
   
    &:hover {
        text-decoration: none;
        color: #007bff;
        transition: 200ms ease-in;
    }
`
const FootNote = styled.p`
    color: white;
    font-size: 12.5px;
    `
const Title = styled.p`
    font-size: 18px;
    color: white;
    margin-bottom: 40px;
    font-weight: bold;
`



 function Icons({className}) {
    return <Icon className={className} />
}
function Footer({ children, ...restProps }) {
    return <Container {...restProps}>{children}</Container>
}

Footer.Wrapper = function FooterWrapper({children, ...restProps}) {
    return <Wrapper {...restProps}>{children}</Wrapper>
}

Footer.Row = function FooterRow({children, ...restProps}) {
    return <Row {...restProps}>{children}</Row>
}

Footer.Column = function FooterColumn({children, ...restProps}) {
    return <Column {...restProps}>{children}</Column>
}

Footer.Link = function FooterLink({children, ...restProps}) {
    return <Link {...restProps}>{children}</Link>
}

Footer.Title = function FooterTitle({children, ...restProps}) {
    return <Title {...restProps}>{children}</Title>
}

Footer.FootNote = function FooterFootNote({children, ...restProps}) {
    return <FootNote {...restProps}>{children}</FootNote>
}





export default function FooterContainer() {
    return(
       <div className= 'footer'>
        <Footer>
            <Footer.Wrapper>
                <Footer.Row>
                <Footer.Column>
                    <Footer.Title>About</Footer.Title>
                    <Footer.Link href="/about">About Us</Footer.Link>
                    <Footer.Link href="/terms">Terms and Conditions</Footer.Link>
                    <Footer.Link href="/privacy-policy">Privacy Policy</Footer.Link>

                </Footer.Column>
                <Footer.Column>
                    <Footer.Title>Socials</Footer.Title>
                    <Footer.Link href="https://www.instagram.com/operationtraddingz268"><Icons className="fa fa-instagram" />Instagram</Footer.Link>
                    <Footer.Link href="https://www.facebook.com/operationtraddingz268"> <Icons className="fa fa-facebook-f" />Facebook</Footer.Link>
                </Footer.Column>
                <Footer.Column>
                    <Footer.Title>Support</Footer.Title>
                    <Footer.Link href="/contact-us">Contact-Us</Footer.Link>
                    <Footer.Link href="mailto: operationtraddingz268@gmail.com">Send an Email (operationtraddingz268@gmail.com)</Footer.Link>
                </Footer.Column>
                </Footer.Row>
            </Footer.Wrapper>
            <Footer.FootNote>
                © 2022 Operation Traddingz 268 - All rights reserved   
            </Footer.FootNote>
            <Footer.FootNote>
        Ferris Farm Development, St.Johns, Antigua
            </Footer.FootNote>
            <Footer.FootNote>
               
                <Icons className="fab fa-cc-visa" />
                <Icons className="fab fa-cc-mastercard" />
                <Icons className="fab fa-cc-diners-club" />
                <Icons className="fab fa-cc-discover" />
            </Footer.FootNote>
            <Footer.FootNote>

            </Footer.FootNote>
            
        </Footer>
    </div>
    
        
    )
}