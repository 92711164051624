import React from "react";
import './policies.css';
import Navbar from '../../ui/navbar';
import Footer from '../../ui/footer'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

export default function Tou() {
    return (
        <>
        <Navbar />
        <motion.div 
          className="body" 
          initial= {{opacity: 0}}
          animate= {{opacity: 1,
          transition: {
          delay: 0.5,
          staggerChildren: 0.5
         }}}>
        <h2><strong>Terms and Conditions</strong></h2>

<p>Welcome to Operation Traddingz 268!</p>

<p>These terms and conditions outline the rules and regulations for the use of Operation Traddingz 268's services.</p>

<p>By accessing this website we assume you accept these terms and conditions. 
    Do not continue to use Operation Traddingz 268 if you do not agree to take all
     of the terms and conditions stated on this page.
     </p>


<h3><strong>Cookies</strong></h3>

<p>We employ the use of cookies. 
    By accessing Operation Traddingz 268, 
    you agreed to use cookies in agreement with the Operation Traddingz 268's 
    <Link to="/privacy-policy">Privacy Policy.</Link> 
</p>

<p>
    Most interactive websites use cookies to let us retrieve the user’s details for each visit. 
    Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website.
    Some of our affiliate/advertising partners may also use cookies.
</p>

<br></br>

<h3><strong>Use of Service</strong></h3>
<p>
2.1 Operation Traddingz 268 provides a ride-hailing service that connects riders with drivers. 
<br></br>
2.2 By using our service, you agree to comply with all applicable laws and regulations in Antigua and Barbuda. 
</p>

<br></br>

<h3><strong>User Conduct</strong></h3>
<p>
3.1 All users are expected to conduct themselves in a safe and respectful manner while using our service. 
<br></br>
3.2 Any behavior that puts the safety of riders or drivers at risk,  or that violates our policies and guidelines, may result in suspension or termination of your subscription. 
<br></br>
3.3 Operation Traddingz 268 reserves the right to deny access to its service to anyone at any time for any reason.
</p>

<br></br>

<h3><strong>User Accounts</strong></h3>
<p>
    4.1 Users must create an account in order to use the Services. 
    <br></br>
    4.2 Users must provide accurate and complete information when creating their accounts and keep their account information up-to-date. 
</p>

<br></br>

<h3><strong>Fare Estimates</strong></h3>
<p>
    5.1 Operations Traddingz 268 provides fare estimates for rides as a convenience to our users. 
    <br></br>
    5.2 These estimates are non-binding and provided for informational purposes only. 
    <br></br>
    5.3 Actual fares may vary due to traffic, weather, and other factors, and may not reflect the actual fare for the ride. 
</p>

<br></br>

<h3><strong>Intellectual Property</strong></h3>

<p>
    6.1 All content on the Operation Traddingz 268 website and mobile application, including but not limited to text, graphics, logos, images,  and software, is the property of Operations Traddingz 268 or its licensors and is protected by applicable copyright and trademark laws in Antigua and Barbuda. 
    <br></br>
    6.2 You (User) may not use any content without the express written consent of Operation Traddingz 268. 
</p>

<br></br>

<h3><strong>Refund Policy</strong></h3>
<p>
    7.1 Operations Traddingz 268 has a strict refund policy. 
    If you wish to request a refund for any reason, please visit<a href="https://ot268.app/refund-policy">https://ot268.app/refund-policy</a> to review our policy and to submit a refund request.
     We reserve the right to refuse any refund requests that do not comply with our policy. 
</p>

<br></br>

<h3><strong>Privacy Policy</strong></h3>
<p>
    8.1 Operations Traddingz 268 takes your privacy seriously.
     We collect and use your personal information only as necessary to provide 
     our ride-hailing services and to comply with applicable laws and regulations. 
     For more information about how we collect, 
     use, and protect your personal information, 
     please review our Privacy Policy at 
     <a href="https://ot268.app/privacy-policy"> https://ot268.app/privacy-policy</a>. 
</p>

<br></br>

<h3><strong>Disclaimers and Limitation of Liability</strong></h3>
<p>
9.1 Operation Traddingz 268 makes no representations or warranties
 about the suitability, reliability, availability, timeliness, or 
 accuracy of the information, software, products, services, or 
 related graphics contained on our website or mobile application 
 for any purpose.
 <br></br>
9.2 To the maximum extent permitted by applicable law in Antigua and Barbuda,
 all such information, software, products, services, and related graphics are provided
"as is" without warranty or condition of any kind.
<br></br>
9.3 In no event shall Operation Traddingz 268 be liable for any direct,
indirect, punitive, incidental, special, consequential damages
or any damages whatsoever including, without limitation, damages
for loss of use, data or profits, arising out of or in any way 
connected with the use or performance of the Operation Traddingz 268 
website or mobile application.
</p>

<br></br>

<h3><strong>Dispute Resolution</strong></h3>
<p>
    10.1 Any disputes between users must be resolved directly between them. 
    <br></br>
    10.2 Operation Traddingz 268 may mediate disputes between users, 
    but is not responsible for resolving payment disputes. 
</p>

<br></br>

<h3><strong>Payment</strong></h3>
<p>
    11.1 Operation Traddingz takes a monthly reccuring payment of $175.00 ECD from drivers. 
    <br></br>
    11.2 Operation Traddingz 268 does not accept electronic payments for rides. 
    The payment for the ride is made directly by the rider to the driver.
    <br></br>
    11.3  Operation Traddingz 268 is a mediator and does not handle any
            payments between riders and drivers.
    <br></br>
    11.4 Any disputes regarding payment or fare must be resolved directly between 
        the rider and the driver, and Operation Traddingz 268 is not
        responsible for resolving such disputes.
</p>

<br></br>

<h3><strong>Termination</strong></h3>
<p>
12.1 Operation Traddingz 268 reserves the right to terminate or suspend
 the subscription and/or account of any user who violates our
  terms and conditions or engages in any behavior that puts the 
  safety of a rider or a drivers at risk.
</p>

<br></br>

<h3><strong>Contact-Us</strong></h3>
<p>
    13.1 If you have any questions about these Terms and Conditions,
        please contact us at
        <a href="mailto: support@ot268.app"/>
</p>

<h3><strong>Modification of Terms and Conditions</strong></h3>
<p>
14.1 Operation Traddingz 268 reserves the right to modify or update
these terms and conditions at any time, without prior notice.
<br></br>
14.2 It is your responsibility to review these terms and conditions
 periodically for any changes.
</p>

<br></br>

<h3><strong>Miscellaneous</strong></h3>
<p>
15.1 These Terms and Conditions constitute the entire agreement 
between users and Operation Traddingz 268.
<br></br>
15.2 If any provision of these Terms and Conditions is found to be invalid or unenforceable,
the remaining provisions will remain in full force and effect.
<br></br>
15.3 Operation Traddingz 268's failure to enforce any provision of these Terms and Conditions 
does not waive its right to enforce that provision or any other provision in the future.
</p>

<br></br>

<h3><strong>Definitions</strong></h3>
<p>
16.1 "Rider" means a user who requests a ride using the Operation Traddingz 268 mobile application.
<br></br>
16.2 "Driver" means a user who provides rides to riders using the Operation Traddingz 268 mobile application.
<br></br>
16.3 "User" means any person who uses the Operation Traddingz 268 mobile application and/or website.
<br></br>
16.4 "Services" means the ride-hailing services provided by Operation Traddingz 268.
<br></br>
16.5 "Fare" refers to the amount of money paid by a rider to a driver for the transportation services provided.
<br></br>
16.6 "Fare estimates" refers to the estimates provided by Operation Traddingz 268 for the cost of a ride, which are for the convenience of users only and may not reflect the actual fare for the ride.
<br></br>
16.7 "Content" refers to any text, graphics, logos, images, and software on the Operation Traddingz 268 website and mobile application.
</p>

<br></br>

<p>
    By using the Operation Traddingz 268 website and mobile application, you agree to these Terms and Conditions.
</p>

        </motion.div>
        <Footer />
        </>
    )
}
