import firebase from 'firebase/compat/app';
import { getAnalytics } from "firebase/analytics";
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const config = {
  apiKey: "AIzaSyDaAXSvImEYUVY1sQd_rrDhZiaUTFqhhsY",
  authDomain: "ot268.app",
  databaseURL: "https://ot268-bdef4-default-rtdb.firebaseio.com",
  projectId: "ot268-bdef4",
  storageBucket: "ot268-bdef4.appspot.com",
  messagingSenderId: "716715848784",
  appId: "1:716715848784:web:05a4a8b394426fa34a4d55",
  measurementId: "G-054LX7G5FH"
};


firebase.initializeApp(config);

getAnalytics();




export const app = firebase.app();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();




