import React, {useState, useRef} from 'react';
import ReCaptcha from 'react-google-recaptcha';
import Axios from 'axios';
import { motion } from 'framer-motion';
import Navbar from '../../ui/navbar';
import Footer from '../../ui/footer';
import { db }  from '../../../firebase';
import './contact.css';





export default function Contact() {

const recaptcha = useRef();

const [subject, setSubject] = useState("");
const [email, setEmail] = useState("");
const [message, setMessage] = useState("");

const [loader, setLoader] = useState(false);

const [error, setError] = useState({});

const [token, setToken] = useState("");
const handleSubmit = (e) => {
  e.preventDefault();
  setLoader(true);
  setError({});
      if(!email || !subject || !message) {
        setError({
          email: "Email is required",
          subject: "Subject is required",
          message: "Message is required"
        });
        setLoader(false);
      }
      else if(!token) {
        setError({
          token: "Please verify that you are not a robot"
        });
        setLoader(false);
      }
else{
  Axios.post('https://us-central1-ot268-bdef4.cloudfunctions.net/checkRecaptcha', {
    email,
    token
}).then(() => { 
db.collection("contacts")
.add({
  subject: subject,
  email: email,
  message: message,
})
.then(() => {
  setLoader(false);
  alert("Your message has been sent! We will get back to you soon.");
  setSubject("");
setEmail("");
setMessage("");
})
.catch((error) => {
  alert(error.message);
  setLoader(false);
});

 
});
}
}


 return (
  <>
  <motion.div 
          className="Container" 
          initial= {{opacity: 0}}
          animate= {{opacity: 1,
          transition: {
          delay: 0.5,
          staggerChildren: 0.5
         }}}>
   <form onSubmit={handleSubmit}>
     <h2 
     style={{color: 'white', textAlign: 'center', marginBottom: '20px'}}
     >Contact Form</h2>
     <p
      style={{color: 'white', textAlign: 'center', marginTop: '10px'}}
     >Connect with us, share your views, leave us questions.</p>

      
       <input 
       type="text" 
       value={subject}
       onChange={(e)=>setSubject(e.target.value)} 
       placeholder="Subject"
       required 
       />
      
      
       <input type="email" 
       value={email} 
       onChange={(e)=>setEmail(e.target.value)}
       placeholder="Email"
       required 
       />

     
       <textarea 
       style={
          {height: '200px'}
        }

       value={message} 
       onChange={(e)=>setMessage(e.target.value)}
       placeholder="Message"
       required 
      />

              <ReCaptcha
                style={
                  {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px 0" ,

                }
                }
                ref={recaptcha}
                sitekey="6LcJpaQfAAAAAF7HjcT6ezqwY5tRbhEiusBD_t9y"
                onChange={token => setToken(token)}
                onExpired={e => setToken("")}
                />
       
     <button type="submit"
     style={{ background: loader ? "#ccc" : " black" }}
     >Submit</button>
      <button 
      onClick={() => window.location.href = '/'}>Back</button>

   </form>
  

</motion.div>
</>
 );
}


