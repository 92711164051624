export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Operation Traddingz 268',
    headline: 'Rides for anyone in Antigua, anywhere in Antigua',
    description:
      'Enjoy the luxury of getting a ride from anywhere by verified taxi drivers near you.',
    buttonLabel: 'Get Started',
    link: '/coming-soon', 
    imgStart: false,
    img: require('../../images/Logo.svg').default,
    alt: 'Download Nowwwww!!!',
    dark: true,
    primary: true,
    darkText: false
  };
  
  export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Become a Driver',
    headline: 'We grant you freedom and incentives you can enjoy',
    description:
      'As a driver, you can work with freedom from anywhere giving rides to customers near you. No direct fees, no interference.',
    buttonLabel: 'Learn More',
    link:'/about', 
    imgStart: true,
    img: require('../../images/driver.svg').default,
    alt: 'might as well...',
    dark: false,
    primary: false,
    darkText: true
  };
  
  
  